import React, { useState, memo, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Script from 'next/script';
import {
  featuredRowQuery,
  agenciesRowQuery,
  residencesRowQuery
} from '../../../queries';
import useBugsnagNotifier from '../../../hooks/useBugsnagNotifier';
import Section from '../../Section';
import NewSearch from '../../NewSearch';
import SectionList from '../../SectionList';
import ItemCard from '../../Cards/ItemCard';
import ResidenceCard from '../../Cards/ResidencesCard/ResidencesHomePageCard';
import PopularRequests from '../../PopularRequests';
import SectionPlaceholder from '../../Placeholders/SectionPlaceholder';
import ItemCardPlaceholder from '../../Placeholders/ItemCardPlaceholder';
import withEndlessScroll from '../../HOC/endlessScroll';
import FallbackView from '../../FallbackView';
import ErrorIcon from '../../../public/static/images/search/icons/icon-error.svg';

const Banner = dynamic(() => import('../../Banner'), { ssr: false });
const AdManagerBanner = dynamic(() => import('../../Banner/AdManagerBanner'), {
  ssr: false
});

let reciviesItems = 24;

const featuredListRef = React.createRef();
const EndlessFeaturedItems = withEndlessScroll(SectionList);

const HomePage = () => {
  const { t } = useTranslation();
  const [isPopState, setIsPopState] = useState(false);
  const router = useRouter();

  useEffect(() => {
    router.beforePopState(({ options }) => {
      // eslint-disable-next-line no-param-reassign
      options.scroll = false;
      window.isPopState = true;

      return true;
    });
    setIsPopState(window.isPopState);

    return () => {
      window.isPopState = false;
    };
  }, []);

  /*
   * Load  data via hooks
   * */
  const [
    {
      loading: agenciesQueryLoading,
      data: agenciesQueryData,
      error: agenciesQueryError
    },
    {
      loading: residencesQueryLoading,
      data: residencesQueryData,
      error: residencesQueryError
    },
    {
      loading: featuredQueryLoading,
      data: featuredQueryData,
      error: featuredQueryError,
      fetchMore
    }
  ] = (() => {
    const agenciesQuery = useQuery(agenciesRowQuery, { ssr: false });
    const residencesQuery = useQuery(residencesRowQuery, { ssr: false });
    const featuredQuery = useQuery(featuredRowQuery({ limit: 24, offset: 0 }), {
      variables: { limit: 24, offset: 0 },
      ssr: false,
      nextFetchPolicy: 'cache-first'
    });

    return [agenciesQuery, residencesQuery, featuredQuery];
  })();

  /*
   * Agencies List
   * */
  const agencies = agenciesQueryData?.agencies || [];
  const agenciesList = agencies
    .filter(a => a.items.length > 0)
    .map(agency => (
      <ItemCard
        key={agency.items[0].id}
        cypress="agency-card-link"
        {...agency.items[0]}
      />
    ));

  /*
   * Residences List
   * */
  const residences = residencesQueryData?.residences || [];
  const residencesList =
    residences &&
    residences.map(residence => (
      <ResidenceCard
        key={residence.id}
        {...residence}
        cypress="residence-card-link"
      />
    ));

  /*
   * Featured List
   * */
  const items = featuredQueryData?.items || [];
  const itemsList =
    items &&
    items.map(item => (
      <ItemCard
        key={item.id}
        {...item}
        cypress="featured-card-link"
        cypress-card="featured-card"
      />
    ));

  const itemsWithBanner = itemsList.reduce((list, currItem, currIndex) => {
    const isTenthElement = currIndex === 9;
    const bottomBanner = (
      <>
        <Banner
          key={'bottom-banner-1'}
          tagName={'p'}
          containerClassName={'lotriver-bottom-banner content-banner'}
          containerId={'js-lotriver-bottom-banner'}
          sid={'220686'}
          bn={'6'}
          arCn={2}
        />
        <AdManagerBanner bannerName={'bottom'} tagName={'p'} />
      </>
    );
    return isTenthElement && !isPopState
      ? [...list, currItem, bottomBanner]
      : [...list, currItem];
  }, []);

  const Error = () => (
    <FallbackView
      Icon={ErrorIcon}
      width="50"
      height="50"
      text={t('search.error.text')}
      title={t('search.error.title')}
      btnText={t('search.error.link')}
      onClickHandler={() => router.reload()}
    />
  );

  const SkeletonLoaders = () => (
    <SectionPlaceholder>
      <ItemCardPlaceholder />
      <ItemCardPlaceholder />
    </SectionPlaceholder>
  );

  useBugsnagNotifier(agenciesQueryError);
  useBugsnagNotifier(residencesQueryError);
  useBugsnagNotifier(featuredQueryError);

  useEffect(() => {
    return () => {
      reciviesItems = 24;
    };
  }, []);

  return (
    <>
      <NewSearch />
      {!residencesQueryLoading && residencesList ? (
        <Section
          id="home-page-residences"
          title={t('sections.residences.title')}
          url="/residences"
          asLink="/yasayis-kompleksleri"
          urlTitle={t('sections.residences.show_all')}
          sectionAdditionalClasses="section-block center-block"
          cypress="residences-section-title"
        >
          {residencesQueryError ? (
            <Error />
          ) : (
            <SectionList listAdditionalClasses="section-list_hide-excess-items">
              {residencesList}
            </SectionList>
          )}
        </Section>
      ) : (
        <SkeletonLoaders />
      )}
      {!isPopState && itemsList.length > 0 && (
        <>
          <Script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
          <Banner
            key="lotriver-top-banner"
            containerClassName={'lotriver-top-banner'}
            containerId={'js-lotriver-top-banner'}
            sid={'220686'}
            bn={'2'}
            arCn={1}
          />

          <AdManagerBanner bannerName={'top'} />
        </>
      )}
      {!agenciesQueryLoading && agenciesList ? (
        <Section
          id="home-page-agencies"
          title={t('sections.agencies.title')}
          url="/agencies"
          asLink="/agentlikler"
          urlTitle={t('sections.agencies.show_all')}
          sectionAdditionalClasses="section-block"
          cypress="agencies-section-title"
        >
          {agenciesQueryError ? (
            <Error />
          ) : (
            <SectionList listAdditionalClasses="section-list--horizontal-scroll">
              {agenciesList}
            </SectionList>
          )}
        </Section>
      ) : (
        <SkeletonLoaders />
      )}
      {!(featuredQueryLoading && !itemsList.length) ? (
        <Section
          id="home-page-featured"
          title={t('sections.featured.title')}
          url="/items/vipped"
          urlTitle={t('sections.vipped.show_all')}
          sectionAdditionalClasses="section-block center-block"
          rightBlock={
            <h1
              className="thumblist-premium-title"
              data-cy="featured-extra-title"
            >
              <Trans i18nKey="sections.featured.right_text" />
            </h1>
          }
          cypress="featured-section-title"
        >
          {featuredQueryError ? (
            <Error />
          ) : (
            <EndlessFeaturedItems
              items={itemsWithBanner}
              loading={featuredQueryLoading}
              ref={featuredListRef}
              isStopLoad={reciviesItems < 24}
              onLoadMore={() => {
                const currentLength = items.length;

                fetchMore({
                  query: featuredRowQuery({
                    limit: 24,
                    offset: currentLength
                  }),
                  variables: {
                    limit: 24,
                    offset: currentLength
                  },
                  notifyOnNetworkStatusChange: true
                }).then(fetchMoreResult => {
                  reciviesItems = fetchMoreResult.data.items.length;
                });
              }}
            >
              {itemsWithBanner}
            </EndlessFeaturedItems>
          )}
        </Section>
      ) : (
        <SkeletonLoaders />
      )}

      <PopularRequests />
    </>
  );
};

export default memo(HomePage);
